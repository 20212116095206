<template>
  <div>
    <file-pond
      name="file"
      ref="pond"
      :class="uniqueId"
      label-idle="Votre fichier ici ..."
      v-bind:allow-multiple="false"
      :server="FILE_UPLOAD_API_URL"
      :accepted-file-types="acceptedFileTypes"
      @removefile="handleFilePondRemoveFile"
      @init="handleFilePondInit"
      @processfile="handleFilePondSuccessProcessed"
      :files="files"
      v-on:init="handleFilePondInit"
    />
    <!-- accepted-file-types="*" -->
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import { FILE_UPLOAD_API_URL } from "../../config/api-config";
import ShortUniqueId from "short-unique-id";
const uuid = new ShortUniqueId({ length: 10 });
function removeTrailingSemicolon(str) {
  return str.replace(/;+$/, "");
}
export default {
  props: {
    acceptedFileTypes: {
      type: String,
      default: [
        "image/jpeg", // JPEG
        "image/png", // PNG
        "image/webp", // WebP
        ".docx", // WebP
        "application/pdf", // PDF
        "application/msword", // DOC (old Word format)
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      ],
      //   default: `image/jpeg, image/png, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
    },
  },
  name: "app",
  data: function () {
    return {
      files: [],
      attachments: [],
      FILE_UPLOAD_API_URL,
      uniqueId: "p" + uuid(),
    };
  },
  methods: {
    handleFilePondInit: function () {
      // FilePond instance methods are available on `this.$refs.pond`
    },
    handleFilePondRemoveFile() {
      this.updateAttachments();
    },
    updateAttachments() {
      let uploadIds = [];
      let filePondDataInputs = document.querySelectorAll(
        `.${this.uniqueId} .filepond--data input`
      );
      filePondDataInputs.forEach((el) => {
        uploadIds.push(JSON.parse(el.value));
      });
      this.attachments = [...uploadIds].map((item) => item.url);
    },
    handleFilePondSuccessProcessed($event) {
      console.log($event);
      this.updateAttachments();
    },
    getFilesUrls() {
      let url = "";
      //   this.attachments.forEach((el) => (url += el + ";"));
      this.attachments.forEach((el) => (url = el));

      return removeTrailingSemicolon(url);
    },
    getSingleFileUrl() {
      let url = "";
      //   this.attachments.forEach((el) => (url += el + ";"));
      this.attachments.forEach((el) => (url = el));

      return removeTrailingSemicolon(url);
    },
  },
  watch: {
    attachments: {
      handler() {
        this.$emit("save", this.attachments);
      },
      deep: true,
    },
  },
  components: {
    FilePond,
  },
};
</script>

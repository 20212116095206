<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '30vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <div class="row grid-md">
                <div class="col-sm-12 field" v-if="!hidePlacementField">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Placement concerné</div>
                    <AsyncSearchInput
                      v-model="editableItem.placement"
                      :queryUrl="'placements/selectable'"
                      :optionLabel="'intituleOffre'"
                      :searchOptions="['intituleOffre']"
                      placeholder="Rechercher un placement par intitule ou référence"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12">
                  <ValidationProvider
                    :rules="`required|pointPresenceExists:${editableItem.placement.id}`"
                    v-slot="{ errors }"
                  >
                    <div>Mois de présence au poste</div>
                    <p-calendar
                      class="col-12 md:col-12 mb-3"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateMoisPointPresence"
                      :manualInput="false"
                      view="month"
                      dateFormat="MM yy"
                      v-model="editableItem.dateMoisPointPresence"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date de début</div>
                    <p-calendar
                      class="col-12 md:col-12 mb-3"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateDu"
                      :manualInput="false"
                      v-model="editableItem.dateDu"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date de fin</div>
                    <p-calendar
                      class="col-12 md:col-12 mb-3"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateAu"
                      :manualInput="false"
                      v-model="editableItem.dateAu"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date premier jour ouvré de la période</div>
                    <p-calendar
                      class="col-12 md:col-12 mb-3"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="datePremierJourOuvre"
                      :manualInput="false"
                      v-model="editableItem.datePremierJourOuvre"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="number"
                        v-uppercase
                        id="nombreJoursOuvres"
                        class="form-control"
                        name="nombreJoursOuvres"
                        v-model="editableItem.nombreJoursOuvres"
                      />
                      <label for="nombreJoursOuvres"
                        >Nombre total de jours ouvrés ouvré de la période</label
                      >
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                    <!-- <small class="text-help"></small> -->
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="nomEmployeTraiteur"
                        class="form-control"
                        name="nomEmployeTraiteur"
                        v-model="editableItem.nomEmployeTraiteur"
                      />
                      <label for="nomEmployeTraiteur">Renseigné par:</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                    <!-- <small class="text-help"></small> -->
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 field mt-3">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <textarea
                        type="text"
                        rows="10"
                        style="height: 120px"
                        id="observation"
                        class="form-control"
                        name="observation"
                        v-model="editableItem.observation"
                      />
                      <label for="observation">Observation</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "../../../common/AsyncSearchInput.vue";

export default {
  components: {
    AsyncSearchInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    hidePlacementField: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: {},
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {},
  computed: {},
  methods: {
    ...mapActions({}),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
